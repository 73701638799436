/* Common Styles ************************************************************ */

/* App Styles *************************************************************** */

html,
body {
  color: #424242;
  font-family: 'Helvetica Neue', 'Calibri Light', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

html,
body,
.page {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#app,
.app {
  height: 100%;
}

/* End App Styles *********************************************************** */

/* Header Styles ************************************************************ */

.header {
  height: 44px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px #eee solid;
  /*box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);*/
}

.title {
  text-align: center;
  line-height: 44px;
  font-size: 24px;
}

.header-button {
  height: 44px;
  width: 44px;
  font-size: 24px;
  background-color: #fff;
  position: fixed;
  border: none;
  z-index: 15;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  margin: 0 auto;
}

.header-button:active {
  background-color: #eee;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.header-button-left {
  padding-left: 8px;
  left: 0;
}

.header-button-right {
  padding-right: 8px;
  right: 0;
}

/* End Header Styles ******************************************************** */

/* Reusable Styles ********************************************************** */

button:active {
  color: #212121;
  background-color: #eee;
}

.error {
  position: relative;
  top: 0;
  width: 100%;
  background-color: #ffa266;
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 5px;
  z-index: 15;
}

.container {
  position: absolute;
  height: auto;
  width: auto;
  top: 44px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  bottom: 44px;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.two-footer {
  bottom: 88px !important;
}

.three-footer {
  bottom: 132px !important;
}

.row {
  flex: 1 0 auto;
  height: 120px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
}

.row-item {
  flex: 1;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
}

.list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.category-row {
  flex: 1 0 auto;
  min-height: 30px;
  max-height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

/* End Reusable Styles ****************************************************** */

/* Footer Styles ************************************************************ */

.footer {
  height: 44px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border: none;
  border-top: #eee 1px solid;
  outline: none;
  font-size: 20px;
  line-height: 38px;
  padding-bottom: 8px;
  /*box-shadow: 0 -10px 10px 10px #fff;*/
}

.second-footer {
  bottom: 44px !important;
}

.third-footer {
  bottom: 88px !important;
}

/* End Footer Styles ******************************************************** */

/* Spinner Styles *********************************************************** */

.react-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: #b9b9b9;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

@keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

@-moz-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

@-webkit-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

/* End Spinner Styles ******************************************************* */

/* End Common Styles ******************************************************** */

/* Login Page *************************************************************** */

.login-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: scroll;
}

.login-message {
  flex-grow: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-title {
  font-size: 58px;
}

.login-form {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-input {
  border-width: 0;
  border-bottom: 1.5px solid #424242;
  margin: 15px auto;
  padding: 8px 0;
  width: 70%;
  font-size: 18px;
  max-width: 400px;
}

.login-input:focus {
  outline: none;
}

/*.login-name {
  text-transform: capitalize;
}*/

.login-error {
  font-size: 16px;
  color: red;
  margin: 0 auto;
}

.login-button {
  padding: 7px;
  margin: 15px auto;
  background-color: transparent;
  border: 1.5px solid #424242;
  border-radius: 30px;
  font-size: 18px;
  width: 30%;
  max-width: 200px;
  font-weight: 500;
}

.login-button:active {
  color: #fff;
  background-color: #424242;
}

.login-loading {
  flex-grow: 1px;
  font-size: 16px;
  text-align: center;
}

.login-switch {
  flex-grow: 6;
}

.switch-login {
  border: none;
  background-color: transparent;
  font-size: 18px;
  text-align: center;
}

/* End Login Page *********************************************************** */

/* User Page **************************************************************** */

.stat {
  flex: 3 0 auto;
  margin: auto;
  align-content: center;
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
}

.stat-number {
  font-size: 42px;
  flex: 3 0 auto;
  margin: auto;
  align-content: center;
  align-self: center;
}

.percent {
  font-size: 12px;
  flex: 1 0 auto;
  align-self: flex-start;
  margin-top: 5px;
}

.stat-name {
  font-size: 12px;
  flex: 1 0 auto;
  margin: auto;
  align-content: center;
  align-self: center;
}

.chart-row {
  flex: 1 0 auto;
  height: 200px;
  width: 100%;
  max-width: 600px;
}

.chart {
  flex: 1;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
}

/* End User Page ************************************************************ */

/* Playing Page ************************************************************* */

.playing-row {
  flex: 1 0 auto;
  width: 100%;
  max-width: 600px;
  max-height: 50px;
  min-height: 50px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.playing-name {
  padding-left: 20px;
}

.playing-checkbox {
  display: none;
}

.add-label {
  color: #a5cf35;
  padding-right: 20px;
  margin-bottom: 2px;
}

.remove-label {
  color: #c44b4b;
  padding-right: 20px;
  margin-bottom: 2px;
}

.search-bar {
  flex: 1 0 auto;
  width: 100%;
  max-width: 600px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-flow: row nowrap;
}

.search-input {
  flex: 1;
  display: block;
  width: auto;
  padding: 0 20px;
  max-width: 250px;
  margin: 0 auto;
  border: none;
  outline: none;
  font-size: 16px;
}

/* End Playing Page ********************************************************* */

/* Order Page *************************************************************** */

.page-container {
  position: absolute;
  height: auto;
  width: auto;
  top: 44px;
  left: 0;
  right: 0;
  overflow-y: hidden;
  bottom: 44px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}

.order-section {
  position: relative;
  height: auto;
  min-height: 100px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.order-list {
  flex: 1;
  width: 100%;
  max-width: 600px;
  padding: 0;
  list-style-type: decimal !important;
  overflow-y: auto;
  outline: none;
  -webkit-overflow-scrolling: touch;
}

.order-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  background-color: transparent;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.order-name {
  align-content: flex-start;
  flex: 1;
}

.order-item-drag {
  align-content: flex-end;
  flex: 1;
  text-align: right;
}

.match-up-section {
  flex: 1;
  height: auto;
  width: 100%;
  max-width: 600px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}

.match-up-row {
  height: 40px;
  min-height: 40px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.match-up-col {
  flex: 1;
  text-align: center;
}

.center {
  text-align: center;
}

/* End Order Page *********************************************************** */

/* Play Game Page *********************************************************** */

.shooter-container {
  flex: 6 0 auto;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.shooter {
  flex: 1 0 auto;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.shooter-name {
  font-size: 32px;
  flex: 1 0 auto;
  text-align: right;
  padding-right: 20px;
}

.shooter-score {
  font-size: 42px;
  flex: 1 0 auto;
  text-align: left;
  padding-left: 20px;
}

.shooter-shots {
  flex: 2 0 auto;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.shot {
  flex: 1 0 auto;
  height: 50px;
  width: 50px;
  text-align: center;
}

.shot-checkbox {
  display: none;
}

.shot-label {
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.made-shot {
  color: #ffa266;
}

.other-shots {
  flex: 1 0 auto;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.other-shot {
  flex: 1 0 auto;
  height: 50px;
  width: 50px;
  text-align: center;
}

.rebounder {
  flex: 1 0 auto;
  height: 30px;
  min-height: 30px;
  width: 100%;
  text-align: center;
}

.other-players {
  flex: 1 0 auto;
  min-height: 40px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.other-player {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 80%;
  max-width: 600px;
}

.other-player-name {
  align-content: flex-start;
  flex: 1;
}

.other-player-score {
  align-content: flex-end;
  flex: 1;
  text-align: right;
}

/* End Play Game Page ******************************************************* */

/* Game Over Page *********************************************************** */

/* End Game Over Page ******************************************************* */

/* Games Page *************************************************************** */

/* End Games Page *********************************************************** */

/* Game Page **************************************************************** */

.game-section {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  margin-top: 44px;
}

.page-container .game-section {
  margin-top: 0;
}

.game {
  height: auto;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-top: 20px;
  flex-shrink: 0;
}

.game-player {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
}

.game-result {
  flex: 1;
  text-align: right;
  align-self: center;
}

.game-shots {
  flex: 8;
}

.game-score {
  flex: 1;
  text-align: left;
  align-self: center;
}

.game-players {
  flex: 1;
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-top: 10px;
}

.with-player {
  display: inline;
}

.player-result {
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin-top: 30px;
}

.player-title {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  min-height: 20px;
  padding-bottom: 5px;
}

.player-name {
  min-height: 20px;
  text-align: left;
  padding-left: 24px;
}

.player-score {
  min-height: 20px;
  text-align: right;
  padding-right: 24px;
}

.shots {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.shot {
  width: 20%;
  height: auto;
  min-height: 20px;
  margin: 0 auto;
}

/* End Game Page ************************************************************ */

/* Edit Game Page *********************************************************** */

/* End Edit Game Page ******************************************************* */

/* Friends Page ************************************************************* */

.friend-button {
  flex: 1 0 auto;
  text-align: right;
  padding-right: 20px;
}

.friend {
  color: #a5cf35;
}

.not-friend {
  color: #c44b4b;
}

/* End Friends Page ********************************************************* */

/* Settings Page ************************************************************ */

.settings-button {
  height: 40px;
  line-height: 40px;
  min-height: 40px;
  width: 100%;
  text-align: center;
}

.settings-button:active {
  background-color: #eee;
}

/* End Settings Page ******************************************************** */

/* HowTo Page *************************************************************** */

.how-list {
  padding-right: 20px;
  max-width: 600px;
  margin: 20px auto;
}

li {
  padding-bottom: 10px;
}

.settings-button:active {
  background-color: #eee;
}

/* End HowTo Page *********************************************************** */
